<template>
  <div>
    <b-container class="mt-4 pb-2" v-if="this.blogs.length > 0">
      <h3 class="font-weight-bolder text-colorBlue text-center pt-4 pb-1">
        Our <span class="text-colorGreen">Blogs</span>
      </h3>
    </b-container>
    <b-container class="mb-5">
      <b-row>
        <b-col md="3" v-for="blog in visibleBlogs" :key="blog.id">
          <b-card
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 30rem"
            class="mb-2 doctor-card d-flex flex-column h-100 bg-colorBlue"
          >
            <div style="height: 5%">
              <b-card-title class="text-colorWhite">
                {{ blog.category_name }}
              </b-card-title>
            </div>

            <div class="doctor-image-container" style="height: 70%">
              <img
                :src="blog.image"
                alt="Blog Image"
                style="width: 100%; height: 300px"
              />
            </div>
            <div style="height: 10%">
              <b-card-text class="text-colorWhite multi-line-truncate mt-1">
                {{ blog.intro }}
              </b-card-text>
            </div>
            <div style="height: 5%">
              <b-button
                :to="{ name: 'Blog', query: { id: blog.id } }"
                block
                variant="primary"
                >Read Blog</b-button
              >
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="blogs.length > 4">
        <b-col class="text-center">
          <b-button @click="toggleViewAll" variant="primary">
            {{ viewAll ? "View Less" : "View All" }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
  },
  data() {
    return {
      isHovered: null,
      blogs: [],
      viewAll: false,
    };
  },
  computed: {
    visibleBlogs() {
      return this.viewAll ? this.blogs : this.blogs.slice(0, 4);
    },
  },
  async mounted() {
    try {
      const res = await this.getBlogPublic({});
      if (res.status === 200) {
        this.blogs = res.data;
        console.log(this.blogs);
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getBlogPublic: "appData/getBlogPublic",
    }),
    toggleViewAll() {
      this.$router.push({ name: "AllBlog" });
    },
    openBlog() {
      this.$router.push({ name: "Blog" });
    },
  },
};
</script>

<style scoped>
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
.doctor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}
.doctor-image-container {
  flex: 1;
}
</style>
