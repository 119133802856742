<template>
  <div class="my-bg mt-5">
    <b-container class="pt-5">
      <b-row>
        <b-col>
          <h1 class="font-weight-bolder text-colorBlue text-center">
            Meet Our Expert Consultants
          </h1>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col
          md="3"
          class="d-flex align-items-stretch justify-content-center mb-1"
          style="position: relative"
          v-for="doctor in visibleDoctors"
          :key="doctor.id"
        >
          <div class="doctor-card d-flex flex-column">
            <div
              class="doctor-image-container"
              v-if="doctor && doctor.profile_image"
            >
              <img
                :src="doctor.profile_image"
                alt="Doctor Image"
                class="rounded"
                style="width: 100%; height: 300px"
              />
            </div>
            <div v-else>
              <!-- Handle case where doctor or profile_image is not available -->
              <span>No profile image available</span>
            </div>

            <div class="bg-colorBlue py-50 px-2 doctor-card rounded">
              <div class="h-75">
                <h4
                  class="font-weight-bolder text-white multi-line-truncate p-0 m-0"
                >
                  {{ doctor.name }}
                </h4>
                <h5 class="text-white font-weight-normal multi-line-truncate">
                  {{ doctor.intro }}
                </h5>
              </div>
              <div>
                <b-button
                  @click="openProfileModal(doctor)"
                  class="mt-50"
                  variant="light"
                  >View Details</b-button
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-3 pb-4" v-if="doctors.length > 4">
        <b-col class="text-center">
          <b-button @click="toggleViewAll" variant="primary">
            {{ viewAll ? "View Less" : "View All" }}
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <doctor-view-modal
      :doctor="selectedDoctor"
      @modalClosed="closeProfileModal"
      :key="`view-${DoctorViewModal}`"
    />
  </div>
</template>

<script>
import DoctorViewModal from "@/components/doctorProfile/DoctorProfileModal.vue";
import { mapActions } from "vuex";

export default {
  components: {
    DoctorViewModal,
  },
  data() {
    return {
      doctors: [],
      showModal: false,
      selectedDoctor: null,
      DoctorViewModal: 0,
      viewAll: false,
    };
  },
  computed: {
    visibleDoctors() {
      return this.viewAll ? this.doctors : this.doctors.slice(0, 4);
    },
  },
  methods: {
    ...mapActions({
      getDoctors: "appData/getDoctorsPublicNav",
    }),
    openProfileModal(doctor) {
      this.DoctorViewModal += 1;
      this.selectedDoctor = doctor;
      this.$nextTick(() => {
        this.$bvModal.show("doctor-view-modal");
      });
    },
    async fetchData() {
      try {
        const response = await this.getDoctors();
        this.doctors = response.data.results;
      } catch (error) {
        console.error("Error fetching doctors:", error);
      }
    },
    closeProfileModal() {
      this.selectedDoctor = null;
    },
    toggleViewAll() {
      this.$router.push({ name: "AllDoctor" });
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/images/specialists-overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
.doctor-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 300px; /* Adjust max-width as needed */
  overflow: hidden;
}
</style>
