<template>
  <div class="my-bg mt-5 px-5">
    <b-container class="py-5">
      <h1 class="text-center mb-1 font-weight-bolder text-colorBlue">
        Stay Updated with our Latest News and Blogs
      </h1>
      <p class="text-center mb-3 font-weight-bold text-colorText">
        Stay informed with the latest news and insights from MedAsk Tours.
        Explore our blogs and updates for expert advice, health tips, and
        industry trends,<br />
        ensuring you're always up to date on all things medical tourism.
        <br />
      </p>
      <b-row class="mt-5">
        <b-col
          md="3"
          class="d-flex align-items-stretch justify-content-start mb-1"
          style="position: relative"
          v-for="blog in displayedBlogs"
          :key="blog.id"
        >
          <div class="doctor-card d-flex flex-column">
            <div class="doctor-image-container">
              <img
                :src="blog.image"
                alt="Blog Image"
                style="width: 100%; height: 300px; border-radius: 12px"
              />
            </div>

            <div
              class="bg-colorBlue px-2"
              style="border-radius: 10px; height: auto"
            >
              <p
                class="text-white font-weight-normal multi-line-truncate pt-50"
              >
                {{ blog.intro }}
              </p>
              <div class="text-center">
                <b-button
                  :to="{ name: 'Blog', query: { id: blog.id } }"
                  class="mb-50"
                  size="sm"
                  variant="light"
                >
                  Read Blog
                </b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <!-- View More / View Less Buttons -->
      <div class="text-center mt-3 mb-3">
        <b-button
          v-if="showViewMore"
          @click="showMore"
          class="mr-2"
          variant="colorGreen"
          size="md"
        >
          View More
        </b-button>
        <b-button
          v-if="showViewLess"
          @click="showLess"
          variant="colorBlue"
          size="md"
        >
          View Less
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      blogs: [],
      displayedBlogs: [],
      increment: 4, // Number of blogs to show or hide on each 'View More' or 'View Less'
      initialCount: 4, // Initial number of blogs to display
      hasViewedMore: false, // Track if "View More" has been clicked
    };
  },
  computed: {
    showViewMore() {
      return this.blogs.length > this.displayedBlogs.length;
    },
    showViewLess() {
      return this.hasViewedMore;
    },
  },
  methods: {
    ...mapActions({
      getBlogPublic: "appData/getBlogPublic",
    }),
    async fetchData() {
      try {
        const response = await this.getBlogPublic();
        this.blogs = response.data;
        this.displayedBlogs = this.blogs.slice(0, this.initialCount); // Initial 8 blogs
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    },
    showMore() {
      this.hasViewedMore = true;
      const currentLength = this.displayedBlogs.length;
      const newLength = Math.min(
        currentLength + this.increment,
        this.blogs.length
      );
      this.displayedBlogs = this.blogs.slice(0, newLength);
    },
    showLess() {
      const currentLength = this.displayedBlogs.length;
      const newLength = Math.max(
        currentLength - this.increment,
        this.initialCount
      );
      this.displayedBlogs = this.blogs.slice(0, newLength);
      if (newLength <= this.initialCount) {
        this.hasViewedMore = false; // Reset flag when less than or equal to initial count
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
};
</script>

<style scoped>
.my-bg {
  background: url("~@/assets/images/images/testimonials-overlay.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
</style>
