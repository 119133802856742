<template>
  <b-container class="py-5">
    <h1 class="text-center mb-1 font-weight-bolder text-colorBlue">
      Frequently Ask Questions
    </h1>
    <b-row>
      <b-col md="6" lg="6" v-for="(faq, index) in faqs" :key="index">
        <FaqQuestionAnswer
          :number="index + 1"
          :question="faq.question"
          :answer="faq.answer"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FaqQuestionAnswer from "./FaqQuestionAnswer.vue";
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  mixins: [util],
  components: {
    FaqQuestionAnswer,
    ToastificationContent,
  },
  data() {
    return {
      isHovered: null,
      faqs: [],
    };
  },
  // computed: {
  //   ...mapState({
  //     cart: (state) => state.appData.cart,
  //   }),
  // },
  async mounted() {
    try {
      // this.initializeCart();

      const res = await this.getFAQPublic({});
      if (res.status === 200) {
        this.faqs = res.data;
        console.log(this.faqs);
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getFAQPublic: "appData/getFAQPublic",
      // addToCart: "appData/addToCart",
      // removeFromCart: "appData/removeFromCart",
      // initializeCart: "appData/initializeCart",
    }),
    // addCart(item) {
    //   this.addToCart({
    //     id: `${item.id}_${this.cartItemType.CAR}`,
    //     itemId: item.id,
    //     name: item.name,
    //     price: item.amount,
    //     type: this.cartItemType.CAR,
    //     quantity: 1,
    //     // viewCart: this.viewCart,
    //     numberOfDays: 1,
    //   });
    //   this.$toast(
    //     {
    //       component: ToastificationContent,
    //       props: {
    //         title: "Item added to cart successfully",
    //         variant: "success",
    //         icon: "BellIcon",
    //       },
    //     },
    //     {
    //       position: "top-right",
    //     }
    //   );
    // },
    // handleCartAction(item) {
    //   if (this.isInCart(item)) {
    //     this.$router.push({ name: "Cart" }); // Navigate to cart page
    //   } else {
    //     this.addCart(item);
    //   }
    // },
    // isInCart(item) {
    //   const cartItemId = `${item.id}_${this.cartItemType.CAR}`;
    //   return this.cart.some((cartItem) => cartItem.id === cartItemId);
    // },
  },
};
</script>

<style scoped></style>
