<template>
  <div>
    <b-container class="mt-4 mb-5 pb-5">
      <h3 class="font-weight-bolder text-colorBlue text-center pt-4 pb-1">
        Patients <span class="text-colorGreen">Stories</span>
      </h3>
      <p class="font-weight-bolder text-colorGreen text-center">
        More Than Treatment, It s a Transformation: Discover Real Patient
        Stories
      </p>
      <p class="font-weight-bolder text-colorText text-center">
        Hear heartwarming tales from real patients like you, who found healing,
        explored new worlds, and felt a transformation that goes beyond borders.
        Their journeys will inspire you to discover your own health and
        adventure.
      </p>
      <b-row class="px-1 mt-5 d-flex justify-content-center">
        <b-col
          md="3"
          class="mr-1 bg-colorBlue rounded border border-3 border-colorGreen mb-5 mb-md-0"
        >
          <div class="position-relative text-center m-0 p-0" style="top: -23%">
            <div class="mt-2 circleBase type1"></div>
          </div>
          <p
            class="text-light px-1 text-center font-weight-bolder"
            style="margin-top: -1.8rem"
          >
            Hospital tourism made my medical journey seamless. The team ensured
            I felt comfortable every step of the way. The facility and doctors
            were top notch. Highly recommended!
          </p>
          <h6 class="text-center font-weight-bolder text-colorGreen pb-1">
            Muhammad Ahmad
          </h6>
          <!-- <div class="position-relative text-center" style="top: -86%">
            <img
              src="@/assets/images/icons/treatments/bone-marrow.svg"
              width="80px"
            />
          </div> -->
        </b-col>
        <b-col
          md="3"
          class="mr-1 bg-colorBlue rounded border border-3 border-colorGreen mb-5 mb-md-0"
        >
          <div class="position-relative text-center m-0 p-0" style="top: -23%">
            <div class="mt-2 circleBase type2"></div>
          </div>
          <div>
            <p
              class="text-light px-1 text-center font-weight-bolder"
              style="margin-top: -1.8rem"
            >
              Hospital tourism changed my perspective. I found quality care
              abroad at a fraction of the cost. The coordination was impeccable,
              and the experience was exceptional.
            </p>
            <h6 class="text-center font-weight-bolder text-colorGreen pb-1">
              Katherine
            </h6>
          </div>
        </b-col>
        <b-col
          md="3"
          class="mr-1 bg-colorBlue rounded border border-3 border-colorGreen mb-5 mb-md-0"
        >
          <div class="position-relative text-center m-0 p-0" style="top: -23%">
            <div class="mt-2 circleBase type3"></div>
          </div>
          <div>
            <p
              class="text-light py-1 text-center font-weight-bolder"
              style="margin-top: -2.5rem"
            >
              Hospital tourism provided high-quality healthcare and adventure. I
              received excellent treatment and had the chance to explore a
              vibrant city. Couldn't be happier!
            </p>
            <h6 class="text-center font-weight-bolder text-colorGreen pb-1">
              Adam Zampa
            </h6>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div class="bg-colorBlue" style="margin-top: 10rem; margin-bottom: 10rem">
      <b-row class="py-5 d-flex align-items-center">
        <b-col md="6" class="px-5">
          <h3 class="p-0 m-0 text-light font weight-bolder">
            Read Real Reviews From Our
          </h3>
          <h2 class="p-0 m-0 text-colorLightBlue font-weight-bolder">
            Valuable Customers About Our Services
          </h2></b-col
        >
        <b-col
          md="6"
          class="px-5 text-center text-md-right pr-md-5 mt-2 mt-md-0"
        >
          <b-button rounded variant="light" size="md" class="font-weight-bolder"
            >View All Reviews
          </b-button></b-col
        >
      </b-row>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.circleBase {
  border-radius: 50%;
}

.type1 {
  width: 80px;
  height: 80px;
  background-image: url("~@/assets/images/images/1.jpg");
  background-size: 80px 80px;
  border: 2px solid #06bd46;
}
.type2 {
  width: 80px;
  height: 80px;
  background-image: url("~@/assets/images/images/2.jpg");
  background-size: 80px 80px;
  border: 2px solid #06bd46;
}
.type3 {
  width: 80px;
  height: 80px;
  background-image: url("~@/assets/images/images/3.jpg");
  background-size: 80px 80px;
  border: 2px solid #06bd46;
}
</style>
