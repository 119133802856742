<template>
  <div v-if="question && answer">
    <app-collapse accordion type="margin" class="mt-2">
      <app-collapse-item title="1">
        <template #header>
          <div class="d-flex">
            <div class="mr-3">
              <span class="font-weight-bolder text-colorGreen"
                >{{ number }}.</span
              >
            </div>
            <div>
              <span
                class="lead collapse-title font-weight-bolder text-colorText"
                style="text-align: justify; display: block"
                >{{ question }}</span
              >
            </div>
          </div>
        </template>
        <div>
          <!-- <span class="font-weight-bolder mr-3 text-colorGreen">
            <feather-icon icon="ArrowRightIcon" size="22" />
          </span> -->
          <div class="ml-4">
            <span
              class="lead collapse-title font-weight-bold text-colorText"
              style="text-align: justify; display: block"
              >{{ answer }}</span
            >
          </div>
        </div>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    BAvatar,
    AppCollapseItem,
    AppCollapse,
  },
  props: {
    number: Number,
    question: String,
    answer: String,
  },
};
</script>
